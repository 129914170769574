/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useQuery,
  useLazyQuery,
  useMutation,
  type OperationVariables,
  type DocumentNode,
  type TypedDocumentNode,
  type QueryHookOptions,
  type QueryResult,
  type MutationHookOptions,
  type MutationTuple,
  type LazyQueryResultTuple,
  useSubscription,
  useApolloClient,
  HttpLink,
  split,
  from,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { useCallback, useEffect, useState } from 'react';

import type { Role } from '@/types/auth';
import type {
  LazyQueryHookOptions,
  SubscriptionHookOptions,
  SubscriptionResult,
} from '@apollo/client';
import type { GraphQLError } from 'graphql';

import { useUser } from '@/hooks/features/auth';
import { useNotice } from '@/hooks/utils';

import { errorLink, httpLink } from '.';

const FILTER_TARGET_ERROR_CODES = ['validation-failed'];

export const useHandleGraphQLError = () => {
  const { showToast } = useNotice();
  const showErrorToast = useCallback(
    (errors: readonly GraphQLError[]) => {
      const filteredErrors = errors.filter(
        (error) =>
          typeof error.extensions.code === 'string' &&
          FILTER_TARGET_ERROR_CODES.includes(error.extensions.code)
      );
      // NOTE: 個別メッセージを出すエラーを含まない場合
      if (filteredErrors.length === 0) {
        console.error(errors);
        showToast({
          status: 'error',
          title: '時間をおいて再度お試しください',
          id: 'unknownError',
        });
        return;
      }

      const messageNode = (
        <ul>
          <li>エラーが発生しました</li>
          {filteredErrors.map((error) => (
            <li key={error.name}>{error.message}</li>
          ))}
        </ul>
      );
      showToast({ status: 'error', title: messageNode });
    },
    [showToast]
  );
  return {
    showErrorToast,
  };
};

export const getRole = ({
  user,
  includes,
}: {
  user: ReturnType<typeof useUser>['user'];
  includes?: 'creator';
}): Role | `${Role}-${'creator'}` | undefined => {
  if (!user) return undefined;
  const rolesOrderByPriority: Role[] = [
    'kg-admin',
    'kg-informer',
    'org-admin',
    'juror',
  ];
  const role = rolesOrderByPriority.find((role) =>
    user.allowedRoles.map((r) => r.name).includes(role)
  );
  return role && includes ? `${role}-${includes}` : role;
};

export const useQueryWrapper = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables> & {
    role?: Role;
    includesRole?: 'creator';
    applicantUserId?: string;
    stripeAccountId?: string;
  }
): QueryResult<TData, TVariables> => {
  const { showErrorToast } = useHandleGraphQLError();
  const { user, isLoading: isUserLoading } = useUser();
  const role =
    options?.role ?? getRole({ user, includes: options?.includesRole });
  return useQuery(query, {
    onError(error) {
      showErrorToast(error.graphQLErrors);
    },
    notifyOnNetworkStatusChange: true, // NOTE: refetchの検知をするため
    ...options,
    context: {
      ...options?.context,
      headers: {
        ...options?.context?.headers,
        ...(options?.applicantUserId
          ? { 'x-applicant-user-id': options.applicantUserId }
          : {}),
        ...(options?.stripeAccountId
          ? { 'x-stripe-account-id': options.stripeAccountId }
          : {}),
        ...(role ? { 'x-hasura-role': role } : {}),
      },
    },
    skip: options?.skip || isUserLoading,
  });
};

export const useLazyQueryWrapper = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<TData, TVariables> & {
    role?: Role;
    includesRole?: 'creator';
    applicantUserId?: string;
    stripeAccountId?: string;
  }
): LazyQueryResultTuple<TData, TVariables> => {
  const { showErrorToast } = useHandleGraphQLError();
  const { user } = useUser();
  const role =
    options?.role ?? getRole({ user, includes: options?.includesRole });
  return useLazyQuery(query, {
    onError(error) {
      showErrorToast(error.graphQLErrors);
    },
    notifyOnNetworkStatusChange: true, // NOTE: refetchの検知をするため
    ...options,
    context: {
      ...options?.context,
      headers: {
        ...options?.context?.headers,
        ...(options?.applicantUserId
          ? { 'x-applicant-user-id': options.applicantUserId }
          : {}),
        ...(options?.stripeAccountId
          ? { 'x-stripe-account-id': options.stripeAccountId }
          : {}),
        ...(role ? { 'x-hasura-role': role } : {}),
      },
    },
  });
};

export const useMutationWrapper = <
  TData = any,
  TVariables = OperationVariables,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables, any> & {
    role?: Role;
    includesRole?: 'creator';
    applicantUserId?: string;
    stripeAccountId?: string;
  }
): MutationTuple<TData, TVariables> => {
  const { showErrorToast } = useHandleGraphQLError();
  const { user } = useUser();
  const role =
    options?.role ?? getRole({ user, includes: options?.includesRole });
  return useMutation(mutation, {
    onError(error) {
      showErrorToast(error.graphQLErrors);
    },
    notifyOnNetworkStatusChange: true, // NOTE: refetchの検知をするため
    ...options,
    context: {
      ...options?.context,
      headers: {
        ...options?.context?.headers,
        ...(options?.applicantUserId
          ? { 'x-applicant-user-id': options.applicantUserId }
          : {}),
        ...(options?.stripeAccountId
          ? { 'x-stripe-account-id': options.stripeAccountId }
          : {}),
        ...(role ? { 'x-hasura-role': role } : {}),
      },
    },
  });
};

export const useSubscriptionWrapper = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  subscription: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: SubscriptionHookOptions<TData, TVariables> & {
    role?: Role;
    includesRole?: 'creator';
    applicantUserId?: string;
    stripeAccountId?: string;
  }
): SubscriptionResult<TData, TVariables> => {
  const { showErrorToast } = useHandleGraphQLError();
  const { user, isLoading: isUserLoading } = useUser();
  const role =
    options?.role ?? getRole({ user, includes: options?.includesRole });

  const client = useApolloClient();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (
      typeof window === 'undefined' ||
      !(client.link.right instanceof HttpLink) ||
      isUserLoading
    ) {
      setIsReady(true);
      return;
    }
    const wsLink = new WebSocketLink({
      uri:
        process.env.NEXT_PUBLIC_KOUBO_API_GRAPHQL_URL?.replace(
          /(http(s?)):\/\//gi,
          'ws$2://'
        ) ?? 'ws://localhost:8080/v1/graphql',
      options: {
        reconnect: true,
        connectionParams: {
          headers: {
            ...options?.context?.headers,
            ...(options?.applicantUserId
              ? { 'x-applicant-user-id': options.applicantUserId }
              : {}),
            ...(options?.stripeAccountId
              ? { 'x-stripe-account-id': options.stripeAccountId }
              : {}),
            ...(role ? { 'x-hasura-role': role } : {}),
          },
        },
      },
    });
    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink
    );
    client.setLink(from([errorLink, splitLink]));
    setIsReady(true);
  }, [
    client,
    isUserLoading,
    options?.applicantUserId,
    options?.context?.headers,
    options?.stripeAccountId,
    role,
  ]);

  return useSubscription(subscription, {
    onError(error) {
      showErrorToast(error.graphQLErrors);
    },
    ...options,
    context: {
      ...options?.context,
    },
    skip: options?.skip || !isReady,
  });
};
